import * as React from "react"

export default function TrialLesson(props) {
    const {trialLessonData, setFormPopup} = props

    return (
        <div className="trialLesson__cont">
            <div className="trialLesson__text">
                <h2>
                    {trialLessonData?.title
                        ? trialLessonData.title
                        : "Оставь заявку на бесплатный пробный урок"}
                </h2>
                <p>
                    {trialLessonData?.text
                        ? trialLessonData.text
                        : "Подробно ознакомишься с нюансами обучения. Познакомишься со школой, пообщаешься с нашими\n" +
                        "                            преподавателям. Ответим на любые вопросы о диджеинге."}
                </p>
            </div>
            <button
                className="button"
                aria-label="send form"
                onClick={() => setFormPopup(true)}
            >
                <span className="flare"></span>
                Оставить заявку
            </button>
        </div>
    )
}
